<template>
  <div>
    <Modal v-model="tipStatus" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix" v-if="showModal == 'post'">
        <img src="../assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>提交</p>
          <p>是否确认提交</p>
        </div>
      </div>
      <div class="topModal clearfix" v-if="showModal == 'delete'">
        <img src="../assets/images/tipsDeleteImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>删除</p>
          <p>是否确认删除</p>
        </div>
      </div>
      <div class="topModal clearfix" v-if="showModal == 'ban'">
        <img src="../assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>禁用</p>
          <p>是否确认禁用</p>
        </div>
      </div>
      <div class="topModal clearfix" v-if="showModal == 'start'">
        <img src="../assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>启用</p>
          <p>是否确认启用</p>
        </div>
      </div>
      <div class="topModal clearfix" v-if="showModal == 'close'">
        <img src="../assets/images/tipsDeleteImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>关闭</p>
          <p>是否确认关闭</p>
        </div>
      </div>
      <div class="topModal clearfix" v-if="showModal == 'success'">
        <img src="../assets/images/tipsSuccessImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>提交成功！</p>
        </div>
      </div>
      <div class="topModal clearfix" v-if="showModal == 'recoil'">
        <img src="../assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>反冲</p>
          <p>是否确认反冲</p>
        </div>
      </div>
      <div class="foot">
        <span id="tc_qx" class="pageBtn finger btnCancle" @click="cancleDelete">取消</span>
        <span id="tc_sc" class="pageBtn finger marginLeft20 btnSure" @click="sureDelete">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'tipsComponent',
  data() {
    return {
      tipStatus: true,
    }
  },
  props: {
    showModal: String,
  },
  methods: {
    cancleDelete() {
      this.$emit('cancleBtn', false)
    },
    sureDelete() {
      this.$emit('sureBrn', true)
    },
  },
}
</script>

<style scoped lang="less">
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 56px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    p:nth-child(1) {
      display: block;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-bottom: 7px;
    }
    p:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}
</style>
